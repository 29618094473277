<template>
  <div class="containerLabsPage w-100">
    <custom-scrollbar>
      <b-container fluid>
        <b-overlay
          v-if="isLoading"
          :show="isLoading"
          no-wrap
          variant="transparent"
        />
        <transition
          mode="out-in"
          name="component-fade"
        >
          <div
            v-if="!isLoading"
            class="pt-4"
          >
            <div class="d-flex mb-4">
              <router-link
                class="mr-4"
                to="/laboratory-orders"
              >
                <ArrowIcon />
              </router-link>
              <h4 class="font-weight-bold">
                Информация о заказе<span style="color: #0D6EFD"> № {{ currentOrderData.number }}</span>
              </h4>
            </div>
            <b-row>
              <b-col>
                <b-form-group
                  label="Статус заказа"
                  label-class="font-weight-bold pt-0"
                >
                  <span>
                    {{ statuses[selectedStatus] }}
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Адрес"
                  label-class="font-weight-bold pt-0"
                >
                  <span>
                    {{ currentOrderData.branch.address }}
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Дата и время создания"
                  label-class="font-weight-bold pt-0"
                >
                  <span>
                    {{ createDateFormat(currentOrderData.createdAt) }}
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="ФИО"
                  label-class="font-weight-bold pt-0"
                >
                  <span>
                    {{ currentOrderData.person.lastName }}
                  </span>
                  <span>
                    {{ currentOrderData.person.firstName }}
                  </span>
                  <span>
                    {{ currentOrderData.person.middleName }}
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Юридическое лицо"
                  label-class="font-weight-bold pt-0"
                >
                  <span>
                    {{ currentOrderData.branch.legalPartner.legalName }}
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="currentOrderData.creationMethod !== 'ApiIntegration'">
              <b-col>
                <b-form-group
                  label="Информация о гарантийных письмах"
                  label-class="font-weight-bold pt-0"
                >
                  <b-row>
                    <b-col>
                      <div
                        v-for="(item, id) in currentOrderData.guaranteeLetters"
                        :key="id"
                        class="mt-2"
                      >
                        <b-button
                          :id="`popover-1-${id}`"
                          class="d-flex align-items-center"
                          size="sm"
                          variant="outline-primary"
                          :type="$const.PRIMARY_BUTTON"
                          @click="onClickDownloadGuarantee(item)"
                        >
                          <b-icon
                            class="mr-2"
                            font-scale="0.85"
                            icon="file-earmark-fill"
                          />
                          Гарантийное письмо
                        </b-button>
                        <b-popover
                          :target="`popover-1-${id}`"
                          placement="righttop"
                          triggers="hover focus"
                          :content="`${item.originalName}`"
                        />
                      </div>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="currentOrderData.promoCode">
              <b-col>
                <b-form-group
                  label="Промокод"
                  label-class="font-weight-bold pt-0"
                >
                  <span>
                    {{ currentOrderData.promoCode.code }}
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="ID платежного ордера"
                  label-class="font-weight-bold pt-0"
                >
                  <span>
                    {{ currentOrderData.paymentOrderId }}
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-4">
              <b-col>
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <h5 class="font-weight-bold">
                      Информация о заказе<span style="color: #0D6EFD"> № {{ currentOrderData.number }}</span>
                    </h5>
                    <h6 class="mr-5 align-self-center">
                      Общая стоимость услуг: <span style="color: #313335">{{ totalCostOfServices }}</span>
                    </h6>
                  </div>
                  <b-table
                    :fields="fields"
                    :items="currentOrderData.basicItems"
                    :small="true"
                    bordered
                    class="bg-white border-secondary mt-4"
                    hover
                  >
                    <template #cell(cost)="row">
                      <span v-if="row.item.cost !== null">
                        {{ `${row.item.cost}р` }}
                      </span>
                    </template>
                  </b-table>
                  <div v-if="currentOrderData.additionalItems">
                    <h6 class="font-weight-bold">
                      Cопутствующие услуги
                    </h6>
                    <b-table
                      :fields="relatedServicesFields"
                      :items="currentOrderData.additionalItems"
                      :small="true"
                      bordered
                      class="bg-white border-secondary mt-3"
                      hover
                    />
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <div class="mt-3 mb-4">
              <input
                ref="inputFile"
                type="file"
                multiple
                style="height: 0px; width: 0px;"
                class="invisible"
                @change="onChooseFiles"
              >

              <div>
                <h6 class="font-weight-bold">
                  Прикрепленные файлы
                </h6>
                <div
                  v-if="attachedAllFiles.length"
                  class="d-inline-block"
                >
                  <div
                    v-for="(fileItem, index) in attachedAllFiles"
                    :key="fileItem.id"
                    class="d-flex justify-content-between align-items-center"
                    :class="{ 'mt-2': index }"
                  >
                    <div>
                      {{ fileItem.name }}
                    </div>
                    <div class="ml-3">
                      <b-button
                        v-if="!fileItem.isNewFile"
                        variant="primary"
                        :type="$const.PRIMARY_BUTTON"
                        size="sm"
                        title="Скачать файл"
                        class="p-1"
                        @click="downloadFile(fileItem)"
                      >
                        <b-icon icon="cloud-download" />
                      </b-button>
                      <b-button
                        variant="danger"
                        :type="$const.PRIMARY_BUTTON"
                        size="sm"
                        title="Удалить файл"
                        class="p-1 ml-1"
                        @click="removeFile(fileItem)"
                      >
                        <b-icon icon="x" />
                      </b-button>
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  class="flex"
                >
                  Файлы не прикреплены
                </div>
              </div>
              <b-button
                v-if="selectedStatus === 'Paid' || selectedStatus === 'Completed'"
                size="sm"
                variant="primary"
                :type="$const.PRIMARY_BUTTON"
                class="mt-2"
                @click="$refs.inputFile.click()"
              >
                Прикрепить файлы
              </b-button>
            </div>
            <b-row>
              <b-col>
                <b-form-group>
                  <b-button
                    v-if="checkFeatureAccess({ name: 'Завершить заказ', url: '/laboratory-order-info/:id' })"
                    v-show="isVisibleCompleteOrder"
                    class="mr-2"
                    size="sm"
                    variant="primary"
                    :type="$const.PRIMARY_BUTTON"
                    :disabled="!this.attachedAllFiles.length"
                    @click="onClickCompleteOrder"
                  >
                    Завершить заказ
                  </b-button>
                  <b-button
                    v-if="checkFeatureAccess({ name: 'Отменить заказ', url: '/laboratory-order-info/:id' })"
                    size="sm"
                    variant="danger"
                    :type="$const.PRIMARY_BUTTON"
                    :disabled="disabledCancelOrder"
                    @click="onClickCancelOrder"
                  >
                    Отменить заказ
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div v-if="rows.length > 0">
                  <b-table
                    :fields="fieldsOrderHistory"
                    :items="rows"
                    :per-page="perPage"
                    :current-page="currentPage"
                    striped
                    bordered
                  >
                    <template #cell(date)="row">
                      {{ formatDate(row.item.date) }}
                    </template>
                  </b-table>
                  <div class="d-flex align-items-baseline my-4">
                    <b-dropdown
                      id="dropdown-1"
                      :text="`${perPage}`"
                      size="sm"
                      class="mr-2"
                      style="height: 35px"
                      split
                      split-variant="outline-primary"
                      variant="primary"
                      :type="$const.PRIMARY_BUTTON"
                    >
                      <b-dropdown-item @click="tableSizeHandler(10)">
                        10
                      </b-dropdown-item>
                      <b-dropdown-divider />
                      <b-dropdown-item @click="tableSizeHandler(25)">
                        25
                      </b-dropdown-item>
                    </b-dropdown>
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rowsTable"
                      :per-page="perPage"
                      aria-controls="my-table"
                      class="mb-0"
                    />
                  </div>
                </div>

                <div
                  v-else
                  class="w-100 bg-white shadow-lg rounded p-4 font-weight-bold"
                >
                  Нет данных
                </div>
              </b-col>
            </b-row>
          </div>
        </transition>
      </b-container>
    </custom-scrollbar>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import * as types from '@/store/types';
import { format, parseISO, addHours } from '@evd3v/date-fns';
import ArrowIcon from 'assets/images/indemnity-arrow-icon.svg';
import { mixinRoles } from '@/mixins';

import { laboratoryService } from '@/services';

import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

export default {
  name: 'LaboratoryOrderInfo',
  components: { ArrowIcon },
  mixins: [validationMixin, mixinRoles],
  props: {
    orderId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      isVisibleCompleteOrder: true,
      selectedStatus: null,
      newFiles: [],
      fields: [
        {
          key: 'service.code',
          label: 'Код услуги Доктис',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'service.name',
          label: 'Наименование услуги',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'cost',
          label: 'Цена',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
      ],
      fieldsOrderHistory: [
        { key: 'date', label: 'Дата и время' },
        { key: 'action', label: 'История изменений' },
      ],
      currentPage: 1,
      perPage: 10,
      relatedServicesFields: [
        {
          key: 'partnerService.code',
          label: 'Код услуги Доктис',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'partnerService.name',
          label: 'Наименование услуги',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'cost',
          label: 'Цена',
          sortable: true,
          tdClass: 'align-middle',
          class: 'td-cost',
        },
      ],
      statuses: {
        WaitPay: 'Ожидает оплаты',
        Paid: 'Оплачен',
        Completed: 'Выполнен',
        Canceled: 'Отменен',
        Received: 'Размещен у партнера',
        InProgress: 'В процессе выполнения',
      },
      rows: [],
    };
  },
  validations: {
    serviceName: { required },
    serviceCode: { required },
    serviceDescription: { required },
  },
  computed: {
    ...mapGetters({
      attachedFiles: types.LABORATORY_ATTACHED_FILES,
      partnerServicesList: types.PARTNER_SERVICES_LIST_GET,
    }),
    currentOrderData() {
      return this.$store.state.Laboratories.currentOrder.data;
    },
    currentResultFiles() {
      return this.currentOrderData?.resultFiles;
    },
    attachedAllFiles() {
      return this.attachedFiles[this.orderId];
    },
    attachedOldFiles() {
      return this.attachedAllFiles.filter((item) => !item.isNewFile);
    },
    attachedNewFiles() {
      return this.attachedAllFiles.filter((item) => item.isNewFile);
    },
    removedFiles() {
      return this.currentResultFiles?.filter((currentItem) => !(this.attachedOldFiles.some((item) => item.id === currentItem.id)));
    },
    serviceListStore() {
      return this.$store.state.Laboratories.serviceList;
    },
    totalCostOfServices() {
      const basicCost = this.currentOrderData.basicItems?.reduce((acc, item) => acc + item.cost, 0) ?? 0;
      const additionalCost = this.currentOrderData.additionalItems?.reduce((acc, item) => acc + item.cost, 0) ?? 0;
      const allCost = additionalCost + basicCost;
      return allCost ? `${basicCost + additionalCost} ₽` : '0 ₽';
    },
    disabledCancelOrder() {
      const { status } = this.currentOrderData;

      switch (status) {
        case 'Canceled':
          return true;
        case 'Completed':
          return true;
        case 'InProgress':
          return true;
        default:
          return false;
      }
    },
    rowsTable() {
      return this.rows.length;
    },
  },
  async created() {
    this.isLoading = true;
    // await this.fetchPartnerServices();
    await this.currentOrderDataFetch();
    this.$store.commit(this.$types.LABORATORY_INITIALIZE_ATTACHED_FILES, {
      orderId: this.orderId,
      oldFiles: this.currentOrderData.resultFiles || [],
    });

    this.selectedStatus = this.currentOrderData.status;

    if (this.currentOrderData.creationMethod === 'ApiIntegration') {
      this.isVisibleCompleteOrder = false;
    }
    this.rows = await laboratoryService.getHistoryOrder(this.orderId);
    this.isLoading = false;
  },
  beforeDestroy() {
    this.$store.commit(this.$types.CURRENT_ORDER_SET, []);
  },
  methods: {
    async currentOrderDataFetch() {
      await this.$store.dispatch(this.$types.GET_LABORATORY_ORDER_FOR_ID, {
        orderId: this.orderId,
        save: true,
      });
    },
    downloadFile(item) {
      this.$store.dispatch(this.$types.LABORATORY_ORDER_DOWNLOAD_RESULT_FILE, { fileId: item.id, name: item.name });
    },
    removeFile(file) {
      this.$store.dispatch(this.$types.LABORATORY_REMOVE_ATTACHED_FILE, { fileId: file.id, orderId: this.orderId });
    },
    onClickDownloadGuarantee(item) {
      this.$store.dispatch(this.$types.LABORATORY_DOWNLOAD_GUARANTEE, item);
    },
    async updateOrderStatus() {
      await this.$store.dispatch(this.$types.LABORATORY_ORDER_STATUS_UPDATE,
        {
          id: this.currentOrderData.id,
          status: this.selectedStatus,
        });
      await this.currentOrderDataFetch();
    },
    async onUploadFiles(files) {
      await this.$store.dispatch(this.$types.LABORATORY_ORDER_UPLOAD_MAIN, { orderId: this.orderId, files });
      await this.currentOrderDataFetch();
    },
    onChooseFiles() {
      const { files } = this.$refs.inputFile;

      Array.from(files).forEach((file) => {
        this.$store.commit(this.$types.LABORATORY_ADD_ATTACHED_FILE, {
          orderId: this.orderId,
          field: {
            id: uuidv4(),
            isNewFile: true,
            name: file.name,
            file,
          },
        });
      });
    },
    async onClickCancelOrder() {
      const data = await this.$store.dispatch(this.$types.LABORATORY_ORDER_CANCEL, this.currentOrderData.id);

      if (data.status === 'success') {
        await this.$router.push('/laboratory-orders');
      }
    },
    async updateFiles() {
      if (this.removedFiles) {
        // eslint-disable-next-line no-restricted-syntax
        for (const file of this.removedFiles) {
          // eslint-disable-next-line no-await-in-loop
          await this.$store.dispatch(this.$types.LABORATORY_ORDER_DELETE_RESULT_FILE, file.id);
        }
      }

      if (this.attachedNewFiles.length) {
        await this.$store.dispatch(this.$types.LABORATORY_ORDER_UPLOAD_RESULT_FILE, {
          orderId: this.orderId,
          files: this.attachedNewFiles.map((item) => item.file),
        });
      }
    },
    async onClickCompleteOrder() {
      await this.updateFiles();

      const data = await this.$store.dispatch(this.$types.LABORATORY_ORDER_COMPLETE,
        this.currentOrderData.id);
      if (data.status === 'success') {
        this.$store.commit(this.$types.LABORATORY_DESTORY_ATTACHED_FILES, this.orderId);
        await this.$router.push('/laboratory-orders');
      }
    },
    uploadDateFormat(row) {
      return row ? format(parseISO(row), 'dd.MM.yyyy') : null;
    },
    createDateFormat(row) {
      return row ? format(parseISO(row), 'dd.MM.yyyy HH:mm') : null;
    },
    openUploadOrderAnalysisModal(row) {
      uiService.showModal(MODALS.UPLOAD_ORDER_ANALYSIS_MODAL, {
        name: 'UploadOrderAnalysis',
        props: {
          analysisId: row.analysis ? row.analysis.id : null,
          fileName: row.analysis ? row.analysis.originalName : null,
          orderItemId: row.id,
          orderId: row.orderId,
        },
      });
    },
    openOrdersHistoryModalModal() {
      uiService.showModal(MODALS.ORDERS_HISTORY_MODAL, {
        name: 'OrdersHistoryModal',
        props: {
          orderId: this.orderId,
        },
      });
    },
    formatDate(date) {
      const dateAdded = addHours(parseISO(date), 3);
      return format(dateAdded, 'dd.MM.yyyy HH:mm');
    },
    async tableSizeHandler(mount) {
      this.perPage = mount;
      this.limit = this.perPage;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.bg-white .td-cost {
  width: 150px;
  text-align: center;
  vertical-align: inherit;
}

</style>
